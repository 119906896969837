import React from 'react'
import { Form, FormControl, Button } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"

class BlogSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            tag: 'all',
            tags: [
                {value: 'all', label: this.props.intl.formatMessage({id: "blogTags.all"})},
                {value: 'news', label: this.props.intl.formatMessage({id: "blogTags.news"})},
                {value: 'best-practices', label: this.props.intl.formatMessage({id: "blogTags.bestpractices"})},
                {value: 'product', label: this.props.intl.formatMessage({id: "blogTags.product"})},
                {value: 'marketing', label: this.props.intl.formatMessage({id: "blogTags.marketing"})},
                {value: 'engineering', label: this.props.intl.formatMessage({id: "blogTags.engineering"})},
                {value: 'staff', label: this.props.intl.formatMessage({id: "blogTags.staff"})}
            ]
        }
    }

    getSearchResults() {
        if (!window.__LUNR__) {
            return [];
        }

        const lunrIndex =  window.__LUNR__[this.props.intl.locale];
        let query = `tags:${this.state.tag} +${this.state.query}`;
        // you can  customize your search , see https://lunrjs.com/guides/searching.html
        if (this.state.tag === 'all') {
            query = `+${this.state.query}`;
        } else if (!this.state.query) {
            query = `tags:${this.state.tag}`;
        }
        const results = lunrIndex.index.search(query);
        return results.map(({ ref }) => lunrIndex.store[ref]).sort((a,b) => {
            return new Date(b.date) - new Date(a.date);
        });
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value

        this.setState({
            query: value
        });
    }

    handleSelectorChange = event => {

        const target = event.target
        const value = target.value

        this.setState({
            tag: value
        });
    }

    handleSearch = event => {
        event.preventDefault();
        if (!this.state.query && this.state.tag === 'all') {
            this.props.handleResults([]);
        } else {
            const results = this.getSearchResults();
            this.props.handleResults(results);
        }
    }

    createOptions(){
        let items = this.state.tags.map((nav, i) => {
            return <option key={i} value={nav.value}>{nav.label}</option>
        });
        return items;
    }

    render() {
        return (
          <div className={`blog-search`}>
              <div className={`form`}>
                  <Form className={`form-area`} onSubmit={this.handleSearch}>
                      <div className={`title`}>
                          <FormattedMessage id="components.search.title" />
                      </div>
                      <div className={`form-row`}>
                          <div className="form-field-group col-md-8">
                              <FormControl type="text" value={this.state.query} placeholder={this.props.intl.formatMessage({ id: "components.search.query.placeholder" })} className="form-field" onChange={this.handleInputChange} />
                          </div>
                          <div className="form-field-group col-md-4">
                              <div className={`select-container`}>
                                  <select className={`form-field form-control`} defaultValue={`all`} onChange={this.handleSelectorChange}>
                                      {this.createOptions()}
                                  </select>
                              </div>
                          </div>
                      </div>
                      <div className={`button`}>
                          <Button className={`form-button`} variant="secondary" type="submit"><FormattedMessage id="components.search.button" /></Button>
                      </div>
                  </Form>
              </div>
          </div>
        )
    }
}

export default injectIntl(BlogSearch)
